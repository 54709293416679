import { Text, Link } from '@components/ui'
import { useRouter } from 'next/router'
import { storeSearchHistory } from './SearchHistory'
import { useEffect, useRef } from 'react'
import Icon from '@components/icons'
interface PredictiveSearchProps {
  searchTerm: string
  searchResult: any
  viewMore: string
  resultTitle: string
}

// eslint-disable-next-line no-unused-vars
function usePrevious(value: any) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

const PredictiveSearch = ({
  searchTerm,
  searchResult,
  viewMore,
  resultTitle,
}: PredictiveSearchProps) => {
  const { locale } = useRouter()

  const boldSearchWord = (text: string, searchText: string) => {
    try {
      const wordIndex = text?.search(new RegExp(searchText, 'i'))
      if (searchText && wordIndex !== -1) {
        const word = text.slice(wordIndex, wordIndex + searchText.length)
        return text.replace(word, `<span class='text-black'>${word}</span>`)
      } else {
        return text
      }
      // start_ai_generated
    } catch (e) {
      console.log('e', e)
      return text
    }
    // end_ai_generated
  }

  // eslint-disable-next-line no-unused-vars
  const getLink = (item: any) => {
    switch (item.type) {
      case 'product':
        return `${locale === 'us' ? '' : '/' + locale}/products${
          item.path
        }?q=${searchTerm.toLowerCase()}`
      case 'page':
        return `/${locale}${item.path}?q=${searchTerm.toLowerCase()}`
      case 'article':
        return `${locale === 'us' ? '' : '/' + locale}/blogs/${
          item.blog.handle
        }/${item.handle}?q=${searchTerm.toLowerCase()}`
      default:
        break
    }
  }

  const getThemeLink = (item: any) => {
    switch (item.type) {
      case 'product':
        return `/products${item.path}?q=${searchTerm.toLowerCase()}`
      case 'page':
        return `/${item.path}?q=${searchTerm.toLowerCase()}`
      case 'article':
        return `/blogs/${item.blog.handle}/${
          item.handle
        }?q=${searchTerm.toLowerCase()}`
      default:
        return ''
    }
  }

  if (!searchTerm || !searchResult?.items.length) {
    return null
  }

  return (
    <div className="flex w-full flex-col py-3 l:mt-[10px]">
      <Text
        html={resultTitle}
        className="my-3 px-3 text-[20px] font-semibold l:text-[18px]"
      />
      {searchResult?.items
        ?.filter((item: any) => !item?.metafields?.seoSetting?.noindex)
        ?.map((item: any) => (
          <Link
            key={item.id}
            dangerouslySetInnerHTML={{
              __html: boldSearchWord(item.title, searchTerm),
            }}
            href={getThemeLink(item)}
            onClick={() => storeSearchHistory(item.title, locale)}
            className="w-full rounded-[8px] px-3 py-3 text-sm font-medium leading-[1.2] text-[#A7A7A7] hover:bg-[#F7F8F9] hover:text-black"
          />
        ))}
      {searchResult?.totalCount > 8 && (
        <Link
          className="mx-auto mt-[20px] flex w-fit items-center text-[16px] font-semibold text-[#00508E] hover:underline"
          href={`/search?q=${searchTerm.toLowerCase()}`}
          onClick={() => storeSearchHistory(searchTerm, locale)}
        >
          {viewMore}
          <Icon iconKey="arrowPlain" fill="#005D8E" />
        </Link>
      )}
    </div>
  )
}

export default PredictiveSearch
