import Cart from './Cart.svg'
import Coupons from './Coupons.svg'
import EufyCredits from './EufyCredits.svg'

const icons = {
  Cart,
  Coupons,
  EufyCredits,
}

export default icons
