import useMount from 'lib/utils/useMount'
import { useRef, useState } from 'react'

// start_ai_generated
export default function useDefaultSearchItem(
  defaultSearchItems: string[] = []
) {
  const [index, setIndex] = useState(0)
  const key = useRef<number>()

  useMount(() => {
    key.current = window.setInterval(() => {
      if (defaultSearchItems.length === 0) return
      setIndex((prev) => (prev + 1) % defaultSearchItems.length)
    }, 5000)

    return () => {
      window.clearInterval(key.current)
    }
  })

  return {
    defaultSearchItem: defaultSearchItems[index],
    clear: () => window.clearInterval(key.current), // 手动清理定时器
  }
}
// end_ai_generated
