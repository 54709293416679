import { Picture, Text, Link } from '@components/ui'
import React, { useCallback, useEffect, useRef } from 'react'
import 'swiper/css'
import 'swiper/css/scrollbar'
import 'swiper/css/grid'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import { useRouter } from 'next/router'

interface RecommendProductsProps {
  title: string
  dataSource: any[]
  pageHandle?: string
}

const RecommendProducts = ({
  title,
  dataSource,
  pageHandle = '',
}: RecommendProductsProps) => {
  const trackRef = useRef(null)
  // eslint-disable-next-line no-unused-vars
  const { locale } = useRouter()

  const onTrack = useCallback(() => {
    if (!dataSource?.length) return
    // track
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'view_item_list',
      event_parameters: {
        page_group: 'Search Page_' + pageHandle,
        item_list_name: 'search_master_bundle',
        items: dataSource.map((item, index) => ({
          item_id: item.sku,
          item_name: item.product_title,
          item_variant: item.variant_title,
          price: item.variant_price,
          index: index,
        })),
      },
    })
  }, [dataSource, pageHandle])

  useEffect(() => {
    const current = trackRef.current
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.8,
    }
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.count('ga4 onTrack')
          onTrack()
        }
      })
    }, options)

    if (current) {
      observer.observe(current)
    }

    return () => {
      if (current) {
        observer.unobserve(current)
      }
    }
  }, [onTrack, trackRef])

  const trackClick = (item: any, index: number) => {
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'select_item',
      event_parameters: {
        page_group: 'Search Page_' + pageHandle,
        item_list_name: 'search_master_bundle',
        items: [
          {
            item_id: item.sku,
            item_name: item.product_title,
            item_variant: item.variant_title,
            price: item.variant_price,
            index: index,
          },
        ],
      },
    })
  }

  if (!dataSource?.length) return null

  const getTags = (product_tags: string) =>
    product_tags
      ?.split(',')
      .filter((t) => t.includes('CLtag'))
      .map((t) => t.replace('CLtag:', ''))
      .join(' | ')

  const getList = (list: any[]) => {
    return list?.map((product: any, index: number) => {
      const {
        product_tags,
        product_title: title,
        variant_image: image,
        variant_url: url,
      } = product

      const tags = getTags(product_tags)
      return (
        <div
          ref={trackRef}
          key={index}
          className="group my-2 w-1/3 pr-14 md:w-full md:pr-4 lg:w-1/2"
        >
          <Link
            href={url}
            className="flex w-full"
            onClick={() => trackClick(product, index)}
          >
            <Picture // @ts-ignore
              source={image}
              className="flex h-[68px] w-[68px] shrink-0 items-center justify-center rounded-[8px] bg-[#F7F8F9] l:h-[74px] l:w-[74px] [&_img]:!h-full [&_img]:object-contain"
              alt={title}
            />
            <div className="flex flex-col justify-center p-3 text-sm text-[#333]">
              {tags && (
                <Text className="line-clamp-1 font-semibold text-[#FF5C00]">
                  {tags}
                </Text>
              )}
              <Text className="line-clamp-2 font-medium group-hover:text-[#00508E]">
                {title}
              </Text>
            </div>
          </Link>
        </div>
      )
    })
  }

  return (
    <div className="mt-10">
      <Text className="text-lg font-semibold">{title}</Text>
      <div className="my-2 flex flex-wrap overflow-hidden">
        {getList(dataSource)}
      </div>
    </div>
  )
}

export default RecommendProducts
