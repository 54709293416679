import React, { useEffect, useState } from 'react'
import Icon from '@components/icons'

export const SEARCH_HISTORY_KEY = 'search_history'
const LIMIT = 10
const DELIMITER = '\n'

function getSearchHistory(locale: string) {
  return (
    localStorage
      .getItem(`${SEARCH_HISTORY_KEY}_${locale}`)
      ?.split(DELIMITER)
      ?.filter(Boolean) ?? []
  )
}

// start_ai_generated
export const storeSearchHistory = (keyword: string, locale: string = 'us') => {
  const list = getSearchHistory(locale)
  list.unshift(keyword)
  localStorage?.setItem(
    `${SEARCH_HISTORY_KEY}_${locale}`,
    [...new Set(list.slice(0, LIMIT))].join(DELIMITER)
  )
}
// end_ai_generated

interface SearchHistoryProps {
  handleClick: (keyword: string) => void
  locale: string
  title: string
}

const SearchHistory = ({ handleClick, locale, title }: SearchHistoryProps) => {
  const [searchHistory, setSearchHistory] = useState<string[]>([])

  useEffect(() => {
    setSearchHistory(getSearchHistory(locale))
  }, [locale])

  const handleClearSearch = () => {
    localStorage?.removeItem(`${SEARCH_HISTORY_KEY}_${locale}`)
    setSearchHistory([])
  }

  if (!searchHistory.length) return null

  return (
    <div className="mt-10">
      <div className="flex justify-between">
        <h3 className="text-lg font-semibold">{title}</h3>
        <Icon
          iconKey={'delete'}
          className="fill-[#999999] hover:fill-[#005D8E]"
          onClick={handleClearSearch}
        />
      </div>
      <div className="mt-4 flex flex-wrap gap-[10px] font-semibold leading-none">
        {searchHistory.map((item: string, index: number) => (
          <span
            className="max-w-[172px] overflow-hidden text-ellipsis whitespace-nowrap break-keep rounded-[20px] bg-[#F7F8F9] px-4 py-[10px] text-[#777777] hover:cursor-pointer hover:bg-[#F1F1F1]"
            key={index}
            onClick={() => {
              handleClick(item)
            }}
          >
            {item}
          </span>
        ))}
      </div>
    </div>
  )
}

export default SearchHistory
